






































































































import Layout from "@/router/layouts/main.vue";
import Vue from 'vue'
import Supplier from "@/components/invoices/edit-invoice/Supplier.vue"
import Purchaser from "@/components/invoices/edit-invoice/Purchaser.vue"
import PaymentInstructions from "@/components/invoices/edit-invoice/PaymentInstructions.vue"
import ServiceTable from "@/components/invoices/edit-invoice/ServiceTable.vue"
import Total from "@/components/invoices/edit-invoice/Total.vue"
export default Vue.extend({
    components: {
        Layout,
        Supplier,
        Purchaser,
        PaymentInstructions,
        ServiceTable,
        Total
    },
    data() {
        return {
            title: "Invoice detail"
        }
    },
})
